import React, { useEffect, useState } from "react";
import Link from '@/utils/ActiveLink';
import * as Icon from 'react-feather';
import { getCookie,hasCookie, setCookie } from 'cookies-next';
import Image from 'next/image'
import BackendConfig from "@/config/backend-config";
import AppContext from '../../AppContext'
import { useContext } from "react";
import { useRouter } from 'next/router'

export default function NavbarYamaha({ company,icon,username, ...props }) {
    const context = useContext(AppContext);
    const router = useRouter();

    const [menu, setMenu] = React.useState(true)
    const [langActive, setLangActive] = useState({label : 'English', image : 'inggris.jpg'})
    const [openLanguage, setOpenLanguage] = useState(false)
    
    const languages = [
        {label: `Indonesia`, value:'/auto/id', image : 'indonesia.png'},
        {label: 'English', value:'/auto/en', image : 'inggris.jpg'},
        {label: `China`, value:'/auto/zh-CN', image : 'china.png'},
    ];

    const toggleNavbar = () => {
        setMenu(!menu)
    }

    const navigate = (url) => {
        const urlNav = `${url}/`
        const urlBar = router.asPath
        
        if(urlNav != urlBar) {
            router.push(url)
        }
    }

    const toggleMenu = (id) => {
        const content = document.getElementById(id)

        if (content.classList.contains('d-block')) {
            content.classList.remove('d-block')
            content.classList.add('drop-down-menu-hide')
        } else {
            content.classList.add('d-block')
            content.classList.remove('drop-down-menu-hide')
        }
    }

    const langChange = (value)=> {
        
        if(convertString(getCookie('googtrans')) != convertString(value)) {
            if(hasCookie('googtrans')){
                setCookie('googtrans',decodeURI(value))
            } else {
                setCookie('googtrans',value)
            }
            
            router.reload();
        }
        
    }

    const googleTranslateElementInit = () => {

        new window.google.translate.TranslateElement({
            pageLanguage: 'auto',
            autoDisplay: false,
            includedLanguages: "ru,en,pl,zh-CN,id",
        },'google_translate_element');
        
        if(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]) {}
    }

    useEffect(() => {
        if(hasCookie('googtrans')){

            for (var key in languages) {
                if(convertString(languages[key]['value']) == convertString(getCookie('googtrans'))) {
                    setLangActive({label : languages[key]['label'], image : languages[key]['image']})
                }
            }
        }
    
        var addScript = document.createElement('script');
        addScript.type = 'text/javascript';
        addScript.async = true;
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;  
    }, [])

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }, [])

    function convertString(string)
    {
        let result = 'en'

        if(string != undefined) {
            const array = string.split('/')
            result = array[2]
        }
        
        return result;
    }

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    return (
        <>
            <header id="header" className="headroom navbar-style-two">
                <div className="header-language">
                    <div className="container">
                        <div className="flags" onClick={() => setOpenLanguage(!openLanguage)}>
                            <div className={`wrap-img flag`}>
                                <Image
                                    alt='Bendera'
                                    src={`/images/bendera/${langActive.image}`}
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                            <label className="notranslate">{langActive.label}</label>
                            <Icon.ChevronDown size={14} color="#FFF" />
                        </div>
                        <div className={`flags-dropdown ${openLanguage ? 'd-block' : 'd-none'}`}>
                            { 
                                languages.map((language, index) => (
                                    <div key={index} className="menu-flag-dropdown mb-3 notranslate" onClick={() => langChange(language.value)}>
                                        <div className={`wrap-img flag`}>
                                            <Image
                                                alt={language.label}
                                                src={`/images/bendera/${language.image}`}
                                                layout='fill'
                                                objectFit='contain'
                                            />
                                        </div>
                                        <label>{language.label}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="startp-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <div className="wrap-img-relative logo">
                                <a onClick={() => navigate(`/`)}>
                                    <Image
                                        src={`${BackendConfig["uri"]}${icon}`}
                                        alt="logo"
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </a>
                            </div>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        
                                        <a onClick={() => navigate(`/`)} className="nav-link">
                                            {
                                                hasCookie('googtrans') 
                                                ?   convertString(getCookie('googtrans')) == 'id'
                                                    ?   'Beranda'
                                                    :   'Home'
                                                :   'Home'
                                            }
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a onClick={() => toggleMenu('drop-product')} className="nav-link nav-drop">
                                            Product <Icon.ChevronDown />
                                        </a>

                                        <ul id="drop-product" className="dropdown-menu drop-down-menu-hide">
                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/product/motor`)} className="nav-link">
                                                    {
                                                        hasCookie('googtrans') 
                                                        ?   convertString(getCookie('googtrans')) == 'id'
                                                            ?   'Produk Unit'
                                                            :   'Unit Product'
                                                        :   'Unit Product'
                                                    }
                                                </a>
                                            </li>

                                            {(company==="yamaha")?(
                                                <li className="nav-item">
                                                    <Link href="https://booking.thamrin.co.id/" activeClassName="active">
                                                        <a target="_blank" onClick={toggleNavbar} className="nav-link">Service Product</a>
                                                    </Link>
                                                </li>
                                            ):""}
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <a onClick={() => navigate(`/news`)} className="nav-link">
                                            News
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a onClick={() => navigate(`/about`)} className="nav-link">
                                            About Us
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a onClick={() => navigate(`/contact`)} className="nav-link">
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div style={{display : 'none'}} className="others-option">
                                {!username || username == "" ? (
                                    <Link href="/company/yamaha/login">
                                        <a className="btn btn-primary">Login</a>
                                    </Link>
                                ) : (
                                    <ul className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <Link href='/#'>
                                                <a onClick={e => e.preventDefault()} className="nav-link" >
                                                    {username} <Icon.ChevronDown />
                                                </a>
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link href="/company/yamaha/Profile" activeClassName="active">
                                                        <a onClick={toggleNavbar} className="nav-link">Profile</a>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link href="/company/yamaha/Shop/Cart">
                                                        <a className="cart-wrapper-btn">
                                                            <Icon.ShoppingCart />
                                                        </a>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link href="/company/yamaha/Shop/Checkout" activeClassName="active">
                                                        <a onClick={toggleNavbar} className="nav-link">Checkout</a>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link href="/product/sparepart" activeClassName="active">
                                                        <a onClick={toggleNavbar} className="nav-link">Pesanan Saya</a>
                                                    </Link>
                                                </li>
                                                {(company=="yamaha")
                                                ?(
                                                    <li className="nav-item">
                                                    <div
                                                        onClick={async (e) => {
                                                            e.preventDefault();
                                                            fetch("/api/auth/logout", {
                                                                method: "POST",
                                                                headers: {
                                                                    "Content-Type": "application/json",
                                                                },
                                                                body: JSON.stringify({ p: "YAMAHA" }),
                                                            }).then((res) => {
                                                                //if (res.ok) {
                                                                window.location.href = "../yamaha";
                                                                // }
                                                                //                     else{
                                            
                                                                //                     }
                                                            });
                                                        }}
                                                    >
                                                        <Link href="https://booking.thamrin.co.id/" activeClassName="active">
                                                            <a target="_blank" onClick={toggleNavbar} className="nav-link">Logout</a>
                                                        </Link>
                                                    </div>
                                                </li>
                                                ):""}
                                            </ul>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

        </>
    );
}